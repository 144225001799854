import NavBar from '../NavBar';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, ContentWrapper, Container, SubHeading } from '../helpers';
import { SecondaryColorText } from '../Home';
import { Download } from '@styled-icons/boxicons-solid/Download';
import { mediaMd, mediaSm } from '../constants';
import { NAVIGATION } from '../NavBar/constants';

function Resume() {
    return (
        <Container>
            <NavBar />
            <ContentWrapper>
                <Heading style={{ position: 'relative' }}>
                    My <SecondaryColorText>Resume</SecondaryColorText>
                    <DownloadResume href="/Aniket_Jain_Resume.pdf" download>
                        <DownloadIcon />
                    </DownloadResume>
                </Heading>
                <SubHeading>
                    If you want to <b>read more</b> about the things I've done,
                    check out my{' '}
                    <Link
                        to={NAVIGATION.PROJECTS.route}
                        style={{ textDecoration: 'none' }}
                    >
                        <b style={{ textDecoration: 'underline' }}>projects</b>
                    </Link>
                    <text> and </text>
                    <Link
                        to={NAVIGATION.EXPERIENCE.route}
                        style={{ textDecoration: 'none' }}
                    >
                        <b style={{ textDecoration: 'underline' }}>
                            work experience
                        </b>
                    </Link>
                </SubHeading>
                <ResumeWrapper>
                    <ResumeImage src="/resume.jpg" />
                </ResumeWrapper>
            </ContentWrapper>
        </Container>
    );
}

export default Resume;

const ResumeWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ResumeImage = styled.img`
    object-fit: contain;
    margin-top: 40px;
    width: 70%;
    border-radius: 20px;
    border: 6px solid var(--secondary-color);
    ${mediaMd} {
        width: 90%;
    }
    ${mediaSm} {
        width: 130%;
        border-width: 4px;
    }
`;

const DownloadIcon = styled(Download)`
    height: 70px;
    width: 70px;
    color: white;
    background: var(--secondary-color);
    border-radius: 20px;
    margin-left: 30px;
    top: 10px;
    position: absolute;
    ${mediaMd} {
        position: static;
        margin: 0px;
        margin-left: 30px;
    }
    ${mediaSm} {
        margin-top: 20px;
        height: 50px;
        width: 50px;
        border-radius: 10px;
        margin: 0px;
    }
`;

const DownloadResume = styled.a`
    ${mediaSm} {
        display: block;
    }
`;
