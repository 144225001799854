import React, { useState } from 'react';
import styled from '@emotion/styled';
import OldSiteRoot from './OldSite';
import NewSiteRoot from './NewSite';

export default function Root() {
  const [useNewSite, setUseNewSite] = useState(true);
  return (
    <Container>{useNewSite ? <NewSiteRoot /> : <OldSiteRoot />}</Container>
  );
}

const Container = styled.div``;
