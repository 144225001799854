import { Experiences as ExperiencesType, TAGS } from './interfaces';

export const EXPERIENCES: ExperiencesType = {
    LUMOS_FULLTIME: {
        name: 'Lumos',
        position: 'Product Engineer',
        description: [
            "Technology gives us superpowers. We believe equipping everyone with superpowers quickly and securely.That's why we are building Lumos, the first AppStore for companies. Employees can go to their Company AppStore and receive the right access to the right apps and permissions for the right amount of time.",
            "The world's most modern companies (Github, Skydio, Vox Media, Getaround, Buzzfeed, Dialpad) use Lumos to make their companies more productive and compliant at the same time. We are backed by Andreessen Horowitz (a16z). Learn more at lumos.com.",
        ],
        date: 'June, 2023 - Present',
        image: '/assets/lumos.png',
        tag: TAGS.full_time,
        website: 'https://www.lumos.com/',
    },
    LUMOS_INTERNSHIP: {
        name: 'Lumos',
        position: 'Software Engineering Intern',
        description: [
            "Technology gives us superpowers. We believe equipping everyone with superpowers quickly and securely.That's why we are building Lumos, the first AppStore for companies. Employees can go to their Company AppStore and receive the right access to the right apps and permissions for the right amount of time.",
            "The world's most modern companies (Github, Skydio, Vox Media, Getaround, Buzzfeed, Dialpad) use Lumos to make their companies more productive and compliant at the same time. We are backed by Andreessen Horowitz (a16z). Learn more at lumos.com.",
        ],
        date: 'January, 2023 - April, 2023',
        image: '/assets/lumos.png',
        tag: TAGS.internship,
        website: 'https://www.lumos.com/',
    },
    AMAZON: {
        name: 'Amazon, Inc.',
        position: 'Software Development Intern',
        description: [
            'During my internship I built a web-based tool to visualize schemas for databases which store personal employee information.',
            'I used multiple technologies like AWS CDK, Cloud Formation, Lambda, and S3 to build a serverless infrastructure with 99.98% uptime.',
            'Access time improved by 2000% from 60 seconds down to 3 seconds, which heavily impacted productivity and accessibility for 100+ engineers at Amazon.',
        ],
        date: 'May 2022 - August 2022',
        image: '/assets/amazon.png',
        tag: TAGS.internship,
    },
    INTROS: {
        name: 'Intros.ai',
        position: 'Technical Product Manager',
        description: [
            'Intros is automating the way 1:1 connections are made in digital communities and has over 20000 active users.',
            'I conduct customer interviews and use the feedback to ideate new features and communicate product vision. I then break features down into detailed, actionable engineering tickets.',
            "I'm also leading frontend development in Typescript/React using a view - view model - model - data model architecture.",
            'Additionally, I help build our RestFul API in Python/Tornado to expand the features we offer and convert leads into customers.',
            'Lastly, I spearheaded our Stripe subscription integration to increase revenue from 0 to over $7500 per month.',
        ],
        date: 'December, 2020 - July, 2022',
        image: '/assets/intros.png',
        website: 'https://www.intros.ai/',
        tag: TAGS.part_time,
    },
    DESAI: {
        name: 'Desai Accelerator',
        position: 'Software Engineer Intern',
        description: [
            'The Desai Accelerator invests in 20 innovative tech startups every year.',
            'Over the summer I have simultaneously built features for 4 startups.',
            'I reduced Leasemagnets’ (YCombinator - Spring, 2021) onboarding time by 15% by automating video creation processes for clients.',
            'I also built Aurora Tights’ wholesale website which increased monthly recurring revenue by 30%.',
        ],
        date: 'May, 2021 - August, 2021',
        image: '/assets/desai.png',
        website: 'https://desaiaccelerator.umich.edu/',
        tag: TAGS.internship,
    },
    CHIME: {
        name: 'Chime Menu',
        position: 'Founding Engineer',
        description: [
            'Chime Menu is a digital menu startup handling 100+ contactless online orders a week in the Ann Arbor area.',
            'I used Typescript/React and Flask to build and optimize a ~20-page user-facing app and interactive server app. I utilized Redux/Contexts for state management, SCSS modules for BEM-based styling, Rollbar/Google Analystics for error handling, and many more tools.',
        ],
        date: 'September, 2020 - April, 2021',
        image: '/assets/chime.jpg',
        website: 'https://chimemenu.com/',
        tag: TAGS.part_time,
    },
    COVIDOPOLY: {
        name: 'Covidopoly',
        position: 'Software Engineer',
        description: [
            'Covidopoly is an online alternative to Monopoly Deal. It has been played over 1 million times by 500,000+ people in 172+ countries. In addition to working at American Express during summer 2020, I helped scale Covidopoly.',
            'Being one of the first five software engineers at Covidopoly, I collaborated with UX designers, graphic designers, and sound engineers to build our mobile application using React Native. I also added features like tracking game highlights to the website using the MERN stack.',
            'I also participated in deliberations about product launch strategies and helped gorw the user base by 300%, reaching over 200,000 users in 3 months.',
        ],
        image: '/assets/covidopoly.png',
        date: 'June, 2020 - March, 2021',
        website: 'https://www.covidopoly.io/',
        tag: TAGS.part_time,
    },
    AMEX_CREDIT: {
        name: 'American Express',
        position: 'Machine Learning Intern',
        description: [
            "I analyzed numerous economic variables to understand why cardholders' credit scores have decreased over the past 7 years despite their expenditure being the same. I utilized the SHAP and LIME Python libraries to run analyses on XGBoost Decision Trees to pin-point the primary reason machine learning models were going wrong- migration to online spending.",
            "Additionally, I created a tool which enabled developers to visualize how much each variable contributed to a machine learning model's prediction.",
            'During my last 2 weeks, I created a new machine learning model which took economic fluctuations into account and has a predicted accuracy of 94% 10 years in the future.',
        ],
        image: '/assets/amex.png',
        date: 'June, 2020 - September, 2020',
        tag: TAGS.internship,
    },
    AMEX_FRAUD: {
        name: 'American Express',
        position: 'Fraud Risk Management Intern',
        description: [
            "Some AMEX cardholders pose as merchants to reverse charges on their credit cards without the merchant's knowledge, effectively increasing the limit on their cards",
            'I analyzed fraudulent behavior in high-risk industries like technology and jewelry to find patterns that fraudsters use and devised various strategies to predict this behavior. I used my findings to design a program that calculates the probability of a cardholder commiting this type of fraud.',
            'The program has been saving AMEX $6MM per year since 2017.',
        ],
        date: 'December, 2017 - Jan, 2018',
        image: '/assets/amex.png',
        tag: TAGS.internship,
    },
    STEVE_AI: {
        name: 'Steve Jobs AI',
        description: [
            'I was devastated when Steve Jobs passed away...',
            'So I made an AI that talks like him.',
            "It uses Python's text-to-speech library to listen to Jobs' keynote speeches and an n-gram model to train itself before delivering an original speech.",
            'Listen to what he has to say!',
        ],
        image: '/assets/steve_ai.jpg',
        date: 'November, 2019',
        tag: TAGS.personal_project,
    },
    MAZE_MAKER: {
        name: 'Maze Visualizer',
        description: [
            'Get mesmerized by watching an algorithm create a maze!',
            'I implemented a recursive backtracking algorithm using Typescript/React, Redux, and styled components.',
            'The Maze visualizer the first website I made from scratch. This personal website is the second!',
        ],
        image: '/assets/maze.jpg',
        date: 'August, 2020',
        github: 'https://github.com/aniketjain576/Maze-Generator',
        website: 'https://mazealgo.com/',
        tag: TAGS.personal_project,
    },
    SUDOKU: {
        name: 'Sudoku Solver and Generator',
        description: [
            'I created a command line game in C++ that solves and creates Sudoku puzzles. It can make puzzles of 3 different difficulties and solve any sudoku board in under 2 seconds.',
        ],
        date: 'May, 2020',
        image: '/assets/sudoku.jpg',
        github: 'https://github.com/aniketjain576/Sudoku',
        tag: TAGS.personal_project,
    },
};
