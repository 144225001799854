import React from 'react';
import styled from '@emotion/styled';
import Navigation from '../Navigation';
import {
    Container,
    ContentWrapper,
    FooterDots,
    Heading as HeadingTemplate,
    SmallFooterDots,
} from '../helpers';
import Introduction from './Introduction';
import { Helmet } from 'react-helmet';
import { mediaMd, mediaSm } from '../constants';

export default function About() {
    return (
        <Container>
            <Helmet>
                <title>Aniket Jain | About</title>
            </Helmet>
            <Navigation />
            <ContentWrapper>
                <AboutPageContentWrapper>
                    <Introduction />
                    <HorizontalSpacer width={90} spacing={40} />
                    <Heading>My life beyond the code...</Heading>
                    <HorizontalSectionWrapper>
                        <Section>
                            <SectionHeader>Iced Coffee</SectionHeader>
                            <SectionDescription>
                                I've been an iced coffee connoisseur ever since
                                I tasted the one served at the Michigan
                                Undergraduate Library.
                            </SectionDescription>
                            <ImageWrapper>
                                <IcedCoffeeImage src="/iced_coffee.jpg" />
                                <ImageCaption>
                                    One of my creations!
                                </ImageCaption>
                            </ImageWrapper>
                        </Section>
                        {window.innerWidth > 800 ? (
                            <VerticalSpacer />
                        ) : (
                            <HorizontalSpacer width={40} spacing={30} />
                        )}
                        <Section>
                            <SectionHeader>Swimming</SectionHeader>
                            <SectionDescription>
                                I've been a competitive swimmer for six years
                                and participated in the Indian Nationals in
                                2018. My favorite strokes are freestyle and
                                butterfly.
                            </SectionDescription>
                            <ImageWrapper>
                                <IcedCoffeeImage src="/dive.jpg" />
                                <ImageCaption>
                                    My first race at the India Northwest
                                    Regionals!
                                </ImageCaption>
                            </ImageWrapper>
                        </Section>
                    </HorizontalSectionWrapper>
                    <HorizontalSpacer width={40} spacing={30} />
                    <Section>
                        <SectionHeader>Spotify</SectionHeader>
                        <SpotifySectionDescription>
                            The piano was my introduction to music. I've been
                            playing for 8 years and have performed at numerous
                            concerts. These days I love finding new songs that
                            combine rap, pop, and hip-hop in new ways.
                        </SpotifySectionDescription>
                        <SectionDescription>
                            These are some of my favorites!
                        </SectionDescription>
                        {window.innerWidth > 800 ? (
                            <Spotify
                                src="https://open.spotify.com/embed/playlist/02rWJqlcGaYoEyMTBYb77X"
                                width="750"
                                height="450"
                                frameBorder="0"
                                allowTransparency={true}
                                allow="encrypted-media"
                            />
                        ) : (
                            <Spotify
                                src="https://open.spotify.com/embed/playlist/02rWJqlcGaYoEyMTBYb77X"
                                width="300"
                                height="250"
                                frameBorder="0"
                                allowTransparency={true}
                                allow="encrypted-media"
                            />
                        )}
                    </Section>
                </AboutPageContentWrapper>
                {window.innerWidth > 800 ? <FooterDots /> : <SmallFooterDots />}
            </ContentWrapper>
        </Container>
    );
}

const Spotify = styled.iframe`
    position: relative;
    z-index: 5;
`;

const HorizontalSectionWrapper = styled.div`
    display: flex;
    margin-top: 32px;

    ${mediaMd} {
        margin-top: 24px;
    }

    ${mediaSm} {
        flex-direction: column;
    }
`;

const HorizontalSpacer = styled.div<{ width: number; spacing: number }>`
    margin: ${({ spacing }) => `${spacing}px 0px`};
    height: 1px;
    width: ${({ width }) => `${width}%`};
    align-self: center;
    background: black;
    border-radius: 20px;

    ${mediaSm} {
        margin: 32px 0px;
        width: 70%;
    }
`;

const Heading = styled(HeadingTemplate)`
    font-size: 44px;

    ${mediaMd} {
        font-size: 40px;
    }

    ${mediaSm} {
        font-size: 28px;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

const SectionHeader = styled.div`
    font-size: 32px;
    font-weight: 600;
    font-family: var(--title);
    letter-spacing: 3px;

    ${mediaMd} {
        font-size: 28px;
    }

    ${mediaSm} {
        font-size: 20px;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const VerticalSpacer = styled.div`
    margin: 0px 32px;
    height: 200px;
    width: 1px;
    align-self: center;
    background: black;
    border-radius: 20px;
`;

const ImageCaption = styled.div`
    text-align: center;
    margin-top: 16px;
    font-size: 0.9em;
    line-height: 18px;

    ${mediaSm} {
        font-size: 0.8em;
    }
`;

const SectionDescription = styled.div`
    margin-top: 16px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 0.9em;
    line-height: 18px;

    ${mediaSm} {
        font-size: 0.8em;
    }
`;

const SpotifySectionDescription = styled(SectionDescription)`
    margin-bottom: 0px;
`;

const IcedCoffeeImage = styled.img`
    height: 200px;
    border-radius: 24px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
`;

const AboutPageContentWrapper = styled.div`
    position: relative;
    padding: 0em 3em;
    display: flex;
    flex-direction: column;

    ${mediaMd} {
        padding: 0em;
    }
`;
