import React from 'react';
import styled from '@emotion/styled';
import { NAVIGATION, NavItem } from './constants';
import { Link, useLocation } from 'react-router-dom';
import { mediaMd } from '../constants';

function NavMenu({ showMenu }: { showMenu: boolean }) {
    const location = useLocation();
    return (
        <Container showMenu={showMenu}>
            <PortraitWrapper showMenu={showMenu}>
                <Portrait />
            </PortraitWrapper>
            <MenuWrapper showMenu={showMenu}>
                <Menu>
                    {getNavOptions().map((navItem: NavItem, index) => (
                        <MenuOption
                            to={navItem.route}
                            delay={(index + 1.35) * 0.095}
                            showMenu={showMenu}
                            currentPage={location.pathname === navItem.route}
                        >
                            {navItem.menuOption}
                        </MenuOption>
                    ))}
                </Menu>
            </MenuWrapper>
        </Container>
    );

    function getNavOptions(): NavItem[] {
        return Object.values(NAVIGATION);
    }
}

export default NavMenu;

const Container = styled.div<{ showMenu: boolean }>`
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    transition: ${({ showMenu }) => (showMenu ? null : 'z-index 1ms ease-out')};
    transition-delay: ${({ showMenu }) => (showMenu ? null : '500ms')};
    z-index: ${({ showMenu }) => (showMenu ? 2 : 1)};
    ${mediaMd} {
        flex-direction: column;
    }
`;

const PortraitWrapper = styled.div<{ showMenu: boolean }>`
    flex: 1;
    height: 100%;
    background: rgba(60, 60, 60, 0.9);
    transition: transform 500ms ease-out;
    transform: translate3d(
        ${({ showMenu }) => (showMenu ? '0, 0, 0' : '0, 100%, 0')}
    );
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaMd} {
        transform: translate3d(
            ${({ showMenu }) => (showMenu ? '0, 0, 0' : '100%, 0, 0')}
        );
    }
`;

const MenuWrapper = styled(PortraitWrapper)`
    background: rgba(43, 43, 43, 0.9);
    transform: translate3d(
        ${({ showMenu }) => (showMenu ? '0, 0, 0' : '0, -100%, 0')}
    );
    flex: 1;

    ${mediaMd} {
        flex: 3;
        transform: translate3d(
            ${({ showMenu }) => (showMenu ? '0, 0, 0' : '-100%, 0, 0')}
        );
        box-sizing: border-box;
        padding-top: 10px;
    }
`;

const Portrait = styled.div`
    width: 225px;
    height: 225px;
    background: url('/portrait.jpg');
    background-position: -35px -15px;
    border-radius: 50%;
    border: solid 3px var(--secondary-color);
    ${mediaMd} {
        width: 150px;
        height: 150px;
        background: url('/portrait_small.jpg');
        background-position: 570px -15px;
    }
`;

const Menu = styled.div`
    min-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const MenuOption = styled(Link)<{
    showMenu: boolean;
    delay: number;
    currentPage: boolean;
}>`
    // margin-bottom: 30px;
    padding: 20px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 30px;
    text-transform: uppercase;
    color: ${({ currentPage }) =>
        currentPage ? 'var(--secondary-color)' : 'white'};
    font-weight: bold;
    cursor: pointer;
    transition: transform 500ms ease-out ${({ delay }) => `${delay}s`},
        color 150ms ease-in;
    transform: translate3d(
        ${({ showMenu }) => (showMenu ? '0, 0, 0' : '600px, 0, 0')}
    );
    :hover {
        color: var(--secondary-color);
    }
    ${mediaMd} {
        transform: translate3d(
            ${({ showMenu }) => (showMenu ? '0, 0, 0' : '-600px, 0, 0')}
        );
        margin-bottom: 20px;
        padding: 10px;
    }
`;
