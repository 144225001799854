import React from 'react';

export const VerticalSquigglyLine = ({
  color = '#000',
  thickness = 6,
  height = 130,
}: {
  color?: string;
  thickness?: number;
  height?: number;
}) => (
  <svg
    width="26"
    height={height}
    viewBox="0 0 26 804"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 802C-0.333333 788.667 -0.333333 775.333 13 762C26.3333 748.667 26.3333 735.333 13 722C-0.333333 708.667 -0.333333 695.333 13 682C26.3333 668.667 26.3333 655.333 13 642C-0.333333 628.667 -0.333333 615.333 13 602C26.3333 588.667 26.3333 575.333 13 562C-0.333333 548.667 -0.333333 535.333 13 522C26.3333 508.667 26.3333 495.333 13 482C-0.333333 468.667 -0.333333 455.333 13 442C26.3333 428.667 26.3333 415.333 13 402C-0.333333 388.667 -0.333333 375.333 13 362C26.3333 348.667 26.3333 335.333 13 322C-0.333333 308.667 -0.333333 295.333 13 282C26.3333 268.667 26.3333 255.333 13 242C-0.333333 228.667 -0.333333 215.333 13 202C26.3333 188.667 26.3333 175.333 13 162C-0.333333 148.667 -0.333333 135.333 13 122C26.3333 108.667 26.3333 95.3333 13 82C-0.333333 68.6667 -0.333333 55.3333 13 42C26.3333 28.6667 26.3333 15.3333 13 2"
      stroke={color}
      stroke-width={thickness}
    />
  </svg>
);

export const HorizontalSquigglyLine = ({
  color = '#000',
  thickness = 6,
  width = 130,
}: {
  color?: string;
  thickness?: number;
  width?: number;
}) => (
  <svg
    width={width}
    height="26"
    viewBox="0 0 804 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M802 13C788.667 26.3333 775.333 26.3333 762 13C748.667 -0.333333 735.333 -0.333333 722 13C708.667 26.3333 695.333 26.3333 682 13C668.667 -0.333333 655.333 -0.333333 642 13C628.667 26.3333 615.333 26.3333 602 13C588.667 -0.333333 575.333 -0.333333 562 13C548.667 26.3333 535.333 26.3333 522 13C508.667 -0.333333 495.333 -0.333333 482 13C468.667 26.3333 455.333 26.3333 442 13C428.667 -0.333333 415.333 -0.333333 402 13C388.667 26.3333 375.333 26.3333 362 13C348.667 -0.333333 335.333 -0.333333 322 13C308.667 26.3333 295.333 26.3333 282 13C268.667 -0.333333 255.333 -0.333333 242 13C228.667 26.3333 215.333 26.3333 202 13C188.667 -0.333333 175.333 -0.333333 162 13C148.667 26.3333 135.333 26.3333 122 13C108.667 -0.333333 95.3333 -0.333333 82 13C68.6667 26.3333 55.3333 26.3333 42 13C28.6667 -0.333333 15.3333 -0.333333 2 13"
      stroke={color}
      stroke-width={thickness}
    />
  </svg>
);
