import React from 'react';
import NavBar from '../NavBar';
import { SecondaryColorText } from '../Home';
import Interests from './Interests';
import Introduction from './Introduction';
import { Container, ContentWrapper, Heading } from '../helpers';

export default function About() {
    return (
        <Container>
            <NavBar />
            <ContentWrapper>
                <Heading>
                    About <SecondaryColorText>Me</SecondaryColorText>
                </Heading>
                <Introduction />
                <Interests />
            </ContentWrapper>
        </Container>
    );
}
