import React from 'react';

export const Logo = ({
  color = '#fff',
  thickness = 2,
  size = 105,
}: {
  color?: string;
  thickness?: number;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 105 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 104.256L44.9804 3.00011"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M88.0529 103.074L45 3.00001"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M103.053 103.074L59.5817 2.02778"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M88.3056 103.384H95.7056H103.106"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M27.9338 43.7101L44.755 59.396"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M21.9338 57.7101L44.6058 78.852"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M61.778 42.8846L45 59.5"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
    <path
      d="M67.778 56.8846L45 79"
      stroke={color}
      stroke-width={thickness}
      stroke-linecap="round"
    />
  </svg>
);
