import { Projects as ProjectType } from '../interfaces';

export const PROJECTS: ProjectType = {
  STEVE_AI: {
    title: 'Steve Jobs AI',
    description: [
      'I was <b>devastated</b> when Steve Jobs passed away...',
      'So I made an <b>AI that talks like him</b>.',
      "It uses <b>Python's text-to-speech</b> library to listen to Jobs' keynote speeches and an <b>n-gram model</b> to <b>train</b> itself before delivering an <b>original speech</b>.",
      '<b>Listen to what he has to say!</b>',
    ],
    image: '/assets/steve_ai.jpg',
    date: 'November, 2019',
  },
  MAZE_MAKER: {
    title: 'Maze Visualizer',
    description: [
      'Get <b>mesmerized</b> by watching an <b>algorithm create a maze</b>!',
      'I implemented a <b>recursive backtracking</b> algorithm using <b>Typescript/React, Redux, and styled components</b>.',
      'The Maze visualizer the <b>first website</b> I made from <b>scratch</b>. This <b>personal website</b> is the <b>second</b>!',
    ],
    image: '/assets/maze.jpg',
    date: 'August, 2020',
    github: 'https://github.com/aniketjain576/Maze-Generator',
    website: 'https://mazealgo.com/',
  },
  SUDOKU: {
    title: 'Sudoku Solver and Generator',
    description: [
      'I created a command line <b>game in C++</b> that <b>solves</b> and <b>creates Sudoku puzzles</b>. It can make puzzles of <b>3 different difficulties</b> and <b>solve</b> any sudoku board in <b>under 2 seconds</b>.',
    ],
    date: 'May, 2020',
    image: '/assets/sudoku.jpg',
    github: 'https://github.com/aniketjain576/Sudoku',
  },
};
